<template>
     <CForm ref="form" class="needs-validation">

        <CRow>
            <CCol col="6">
                <CSelect
                    label="Rapor Tipi"
                    type="text"
                    horizontal
                    placeholder="Seçiniz"
                    :options="reportTypes"
                    :value.sync="data.reportType"
                    required
                />
            </CCol>
            <CCol col="6">
                <CSelect
                    label="Ülke"
                    type="text"
                    horizontal
                    placeholder="Seçiniz"
                    :options="countries"
                    :value.sync="data.countryId"
                />
            </CCol>
        </CRow>
        <CRow>
            <CCol col="6">
                <CInput
                    label="Başlangıç Tarihi"
                    horizontal
                    type="date"
                    v-model="data.startDate"
                    required
                />
            </CCol>
            <CCol col="6">
                <CInput
                    label="Bitiş Tarihi"
                    horizontal
                    type="date"
                    v-model="data.endDate"
                    required
                />
            </CCol>
        </CRow>
        <CRow>
            <CCol col="12">
                <CInput
                    label="Min. Sipariş Tutarı"
                    horizontal
                    v-model="data.amount"
                    required
                />
            </CCol>
        </CRow>
    </CForm>
</template>

<script>

    export default{
        name: "adCamExcelReportForm",
        props:{
            validated: Boolean,
            params: Object,
            actionType: String,
        },
        watch:{
            params: function(val){
                this.data = {...val};
            },
            actionType:  function(val){
                if(val == 'create')
                    this.data = {...this.params}
            },
        },

        computed:{
            countries() {
                let data = []
                this.$store.getters.allCountries.map(c => data.push({label: c.name, value: c.id}))
                return data
            },
        },

        data: function(){
            return{
                data: this.params ? {...this.params} : {},
                reportTypes: [
                    {label: "Genel Satış", value: "general"},
                    {label: "İkincisi %50", value: "2nd50"},
                    {label: "3 Al 2 Öde", value: "buy3pay2"},
                ]
            }
        },
    }
</script>